<template>
  <b-modal
    id="modal-third-party-integration"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="Third Party Integration"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickSendIntergrationRequest)"
        >
          <b-row>
            <b-col cols="7">
              <b-form-group
                label="Select User"
                label-for="h-user"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="user"
                  rules="required"
                >
                  <v-select
                    v-model="selectedUser"
                    :options="userOptions"
                    :reduce="option => option.id"
                    deselect-from-dropdown
                    label="first_name"
                    placeholder="Select User"
                    :disabled="isDisabledUser"
                    @input="selectUser"
                  >
                    <template v-slot:option="option">
                      {{ option.first_name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-show="isVisible"
          >
            <b-row class="d-flex justify-content-center">
              <b-col cols="7">
                <b-form-group
                  label="Hash Code"
                  label-for="h-hashCode"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="hashCode"
                  >
                    <b-form-input
                      id="hashCode"
                      v-model="hashCode"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Hash Code"
                      type="text"
                      autocomplete="nope"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary="'Hash code can be copied by clicking this icon!'"
                  variant="outline-primary"
                  class="mt-2 justify-content-center d-flex copyClientData"
                  @click="copyHash"
                >
                  <feather-icon icon="LinkIcon" />
                  Copy Hash
                </b-button>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col cols="7">
                <b-form-group
                  label="URL"
                  label-for="h-url"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="url"
                  >
                    <b-form-input
                      id="url"
                      v-model="apiUrl"
                      :state="errors.length > 0 ? false:null"
                      placeholder="URL"
                      type="text"
                      autocomplete="nope"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary="'URL can be copied by clicking this icon!'"
                  variant="outline-primary"
                  class="mt-2 justify-content-center d-flex copyClientData"
                  @click="copyURL"
                >
                  <feather-icon icon="LinkIcon" />
                  Copy URL
                </b-button>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center">
              <b-col cols="7">
                <b-form-group
                  label="Tenant"
                  label-for="h-tenant"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="tenant"
                  >
                    <b-form-input
                      id="tenant"
                      v-model="tenant"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Tenant"
                      type="text"
                      autocomplete="nope"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary="'Tenant can be copied by clicking this icon!'"
                  variant="outline-primary"
                  class="mt-2 justify-content-center d-flex copyClientData"
                  @click="copyTenant"
                >
                  <feather-icon icon="LinkIcon" />
                  Copy Tenant
                </b-button>
              </b-col>
            </b-row>
          </div>
          <!-- submit and reset -->
          <b-row
            v-show="buttonIsVisible"
          >
            <b-col>
              <div class="d-flex justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="custom-button-color"
                  :disabled="isDisabled"
                >
                  Request
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import {
  tenant,
  apiUrl,
} from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    selectedUser: null,
    userOptions: [],
    modalLoading: false,
    loading: false,
    tenant,
    isVisible: false,
    hashCode: null,
    buttonIsVisible: true,
    isDisabled: true,
    isDisabledUser: false,
    apiUrl,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(businessId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.businessId = businessId
      this.modalLoading = true
      await this.getUserListForDropdown()
      await this.getIntergrationById()
      this.modalLoading = false
    },
    async copyItem(value, name) {
      try {
        await navigator.clipboard.writeText(value.toString())
        const message = `${name} has been copied successfully!`
        this.showSuccessMessage(message)
      } catch (error) {
        this.showErrorMessage('Failed to copy item')
      }
    },

    // copyMerchantPortalURL method
    copyHash() {
      this.copyItem(this.hashCode, 'Hash Code')
    },

    // copyapiDocumentationUrl method
    copyURL() {
      this.copyItem(apiUrl, 'URL')
    },

    copyTenant() {
      this.copyItem(tenant, 'Tenant')
    },

    async getUserListForDropdown() {
      this.loading = true
      try {
        const res = await MerchantRepository.getUserListForDropdown(this.businessId)
        this.userOptions = res.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },

    async selectUser(value) {
      try {
        if (value == null) {
          this.isDisabled = true
        } else {
          this.isDisabled = false
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickSendIntergrationRequest() {
      this.modalLoading = true
      try {
        const payload = {
          merchant_user_id: this.selectedUser,
        }

        const res = await MerchantRepository.sendIntergrationRequest(this.businessId, payload)
        if (res.status === 200) {
          this.showSuccessMessage('Merchant integration request is sent')
          this.clearForm()
        } else {
          this.showErrorMessage('Failed to send request')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },

    async getIntergrationById() {
      try {
        const res = await MerchantRepository.getIntergrationById(this.businessId)
        if (res.data.data.merchant_intergration != null) {
          const merchantUserId = res.data.data.merchant_intergration.merchant_user_id
          this.selectedUser = this.userOptions.find(item => item.id === merchantUserId)
          if (res.data.data.merchant_intergration.integration_status === 'pending') {
            this.isVisible = false
            this.buttonIsVisible = true
            this.isDisabledUser = false
          } else if (res.data.data.merchant_intergration.integration_status === 'approved') {
            this.isVisible = true
            this.hashCode = res.data.data.merchant_intergration.hash
            this.buttonIsVisible = false
            this.isDisabledUser = true
          }
        } else {
          this.isVisible = false
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
