<template>
  <b-modal
    id="modal-merchant-business-add"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="ADD NEW MERCHANT BUSINESS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="createMerchantBusinessForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickCreateMerchantBusiness)"
        >
          <b-row>
            <b-col cols="4" />
            <b-col cols="8">
              <div class="d-flex mt-1 mb-1">
                <feather-icon
                  icon="BriefcaseIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Merchant Primary Business Details
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Name"
                label-for="h-name"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the merchant name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Address Line 1"
                label-for="h-addressLine1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine1"
                  rules="required"
                >
                  <b-form-input
                    id="addressLine1"
                    v-model="form.addressLine1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 1"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Address Line 2"
                label-for="h-addressLine2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine2"
                  rules=""
                >
                  <b-form-input
                    id="addressLine2"
                    v-model="form.addressLine2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 2"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Address Line 3"
                label-for="h-addressLine3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine3"
                  rules=""
                >
                  <b-form-input
                    id="addressLine3"
                    v-model="form.addressLine3"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 3"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Postal /Zip Code"
                label-for="h-postalCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="postalCode"
                  rules="required"
                >
                  <b-form-input
                    id="postalCode"
                    v-model="form.postalCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the postal /zip Code"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Country"
                label-for="h-country"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-select
                    v-model="form.country"
                    :options="countryOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select the country"
                    autocomplete="nope"
                    @input="fetchStateList()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="State"
                label-for="h-state"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="state"
                  rules="required"
                >
                  <v-select
                    v-model="form.state"
                    :options="stateOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select the state"
                    autocomplete="nope"
                    @input="fetchCityList()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    v-model="form.city"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select the city"
                    autocomplete="nope"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Contact No."
                label-for="h-contactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="contactNo"
                  rules="required"
                >
                  <b-form-input
                    id="contactNo"
                    v-model="form.contactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contact no."
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Email"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the email"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4" />
            <b-col cols="8">
              <div class="d-flex mt-1 mb-1">
                <feather-icon
                  icon="DollarSignIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Bank Details
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Account Name"
                label-for="h-accountName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="accountName"
                  rules="required"
                >
                  <b-form-input
                    id="accountName"
                    v-model="form.accountName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the account name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Account No."
                label-for="h-accountNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="accountNo"
                  rules="required"
                >
                  <b-form-input
                    id="accountNo"
                    v-model="form.accountNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the account no"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Bank Name"
                label-for="h-bankName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="bankName"
                  rules="required"
                >
                  <b-form-input
                    id="bankName"
                    v-model="form.bankName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the bank name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Branch Name"
                label-for="h-branchName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="branchName"
                  rules="required"
                >
                  <b-form-input
                    id="branchName"
                    v-model="form.branchName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the branch name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Branch Code"
                label-for="h-branchCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="branchCode"
                >
                  <b-form-input
                    id="branchCode"
                    v-model="form.branchCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the branch code"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    form: {},
    modalLoading: false,
    loading: false,
    cityOptions: [],
    stateOptions: [],
    countryOptions: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId) {
      Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.modalLoading = true
      await this.fetchCountryList()
      this.modalLoading = false
    },
    async fetchCityList() {
      try {
        this.loading = true
        const { data } = (await ResourceRepository.getCityList(this.form.state)).data
        this.cityOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchStateList() {
      try {
        const { data } = (await ResourceRepository.getStateDropdownList(this.form.country)).data
        this.stateOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchCountryList() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickCreateMerchantBusiness() {
      this.modalLoading = true
      try {
        const payload = {
          name: this.form.name,
          business_name: this.form.name,
          bank_details: {
            account_name: this.form.accountName,
            account_number: this.form.accountNo,
            bank_name: this.form.bankName,
            branch_name: this.form.branchName,
            branch_code: this.form.branchCode,
          },
          address_details: {
            address_line_1: this.form.addressLine1,
            address_line_2: this.form.addressLine2,
            address_line_3: this.form.addressLine3,
            city_id: this.form.city,
            state_id: this.form.state,
            phone: this.form.contactNo,
            country_id: this.form.country,
            postal_code: this.form.postalCode,
            type: 'general',
          },
        }

        const res = await MerchantRepository.createMerchantBusiness(payload)
        const businessId = res.data.data.id
        const addressId = res.data.data.business_address.id
        if (res.status === 201) {
          const contactPayload = {
            contact_details: {
              phone: this.form.contactNo,
              email: this.form.email,
              is_default: true,
            },
          }
          const contactRes = await MerchantRepository.createMerchantContact(businessId, addressId, contactPayload)
          if (contactRes.status === 201) {
            this.showSuccessMessage('Merchant business created successfully')
            this.open = false
            this.$parent.onClickRefresh()
          } else {
            this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
          }
        } else {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
@import '../../@core/scss/button-color.scss';
</style>
